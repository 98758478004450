"use client";

import { useDynamicTextareaSize } from "../../lib/hooks/use-dynamic-textarea-size";
import { useRef, useState, useEffect, Fragment } from "react";
import { Input } from "../shadcn/input";
import { Textarea } from "../shadcn/textarea";
import { Slider } from "../shadcn/slider";
import RadioOption from "./radio-option";
import Required from "./required";
import { FormControl, FormDescription, FormField, FormItem, FormLabel } from "../shadcn/form";
import { Alert, AlertDescription } from "../shadcn/alert";
import { AlertCircle } from "lucide-react";
import { Question } from "@shared/types";

type PromptQuestionProps = {
  question: Question;
  index: number;
};
const INPUT_TYPES = ["text", "short-text", "scale", "radio"];

export function PromptQuestion({
  question: { placeholder, answer, question, instruction, type, typeOptions, required },
  index,
}: PromptQuestionProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState("");
  useDynamicTextareaSize(textareaRef, text);
  const finalPlaceholder = placeholder || "Type your answer here...";

  useEffect(() => {
    console.log(answer);
    if (answer) setText(answer); // Update text state when value prop changes
  }, [answer]);

  const radioOptions = typeOptions?.radio ? typeOptions.radio.options : [];

  return (
    <FormField
      rules={{
        required: required ? "This field is required" : false,
        onChange: (e) => setText(e.target.value)
      }}
      name={`question-${index}-${type}`}
      render={({ field, fieldState: { error } }) => (
        <FormItem>
          <FormLabel className="text-xl mt-10 mb-2">
            {question}
            {required && <Required />}
          </FormLabel>
          {instruction && <FormDescription className="text-gray-500 mb-6">{instruction}</FormDescription>}
          {!instruction && <div className="mb-6" />}
          <FormControl>
            {INPUT_TYPES.includes(type) ? (
              <Fragment>
                {type === "short-text" && (
                  <Input
                    {...field}
                    value={text}
                    placeholder={finalPlaceholder}
                    className="bg-gray-100 border-none max-w-xs"
                  />
                )}
                {type === "text" && (
                  <Textarea
                    {...field}
                    ref={textareaRef}
                    value={text}
                    className="h-[30px] w-full bg-gray-100 border-none"
                    placeholder={finalPlaceholder}
                  />
                )}

                {type === "scale" && (
                  <div className="flex items-center gap-4">
                    <Slider
                      className="max-w-xs"
                      max={typeOptions?.scale?.max}
                      min={typeOptions?.scale?.min}
                      step={1}
                      defaultValue={[field.value ? parseInt(field.value) : 1]}
                      onValueChange={(vals) => {
                        field.onChange(vals[0].toString());
                      }}
                    />
                    <span className="leading-none mb-1 text-lg font-semibold">{text}</span>
                  </div>
                )}
                {type === "radio" && (
                  <div className="flex flex-col gap-2 max-w-max">
                    {radioOptions.map((opt, k) => (
                      <RadioOption
                        option={opt}
                        isSelected={text === opt.value}
                        index={k}
                        key={question + type + opt.value}
                        {...field}
                      />
                    ))}
                  </div>
                )}
              </Fragment>
            ) : (
              <Textarea
                {...field}
                ref={textareaRef}
                value={text}
                className="h-[30px] w-full bg-gray-100 border-none"
                placeholder={finalPlaceholder}
              />
            )}
          </FormControl>
          {error && (
            <div className="max-w-sm !mt-6">
              <Alert variant="destructive" className="h-9 flex items-center gap-4 bg-destructive/5 !px-4">
                <AlertCircle className="h-4 w-4 !relative !inset-0" />
                <AlertDescription className="mt-0.5">{error.message || "This field is required"}</AlertDescription>
              </Alert>
            </div>
          )}
        </FormItem>
      )}
    />
  );
}