import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link } from "react-router-dom";

interface Page {
  name: string;
  href: string;
  current: boolean;
}

interface BreadcrumbsProps {
  pages: Page[];
}

export function Breadcrumbs({ pages }: BreadcrumbsProps) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        {pages.map((page, index) => (
          <li key={page.name}>
            <div className="flex items-center">
            <Link to={page.href}
                className="mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {page.name}
              </Link>
              {index < pages.length - 1 && <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}
