import { useState, useEffect } from "react";
import { PromptAnswer } from "@shared/types/prompt";
import { useStreamText } from "@/queries/aiStreaming"; // Updated import
import { useGetName } from "@/hooks/useGetName";
import { cleanUpPromptAnswer, populatePromptResponse } from "./utils";
import Markdown from "markdown-to-jsx";
import { useAddPromptResponse } from "@/queries/promptAnswers";
import { Copy, RefreshCw, ThumbsDown, ThumbsUp } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@shadcn/tooltip";
import "@/styles/md-container.css";
import { useCopyToClipboard } from "usehooks-ts";
import { useToast } from "@shadcn/use-toast";
import { Button } from "@shared/components/tremor/button";
import { AskHumanCoachForm } from "./AskHumanCoachForm";
import { useEditPromptAnswerById } from "@/queries/promptAnswers";
import { LangfuseWeb } from "langfuse";

interface Coach {
  name: string;
  description: string;
  prompt_id: string;
  whitelist_category?: string[];
  icon?: string;
}

interface AiCoachResponseProps {
  activeKey: string;
  coach: Coach;
  promptAnswer: PromptAnswer;
  response: string;
  setResponse: (state: any) => void;
}

export function AiCoachResponse({ activeKey, coach, promptAnswer, response, setResponse }: AiCoachResponseProps) {
  const [userInput, setUserInput] = useState("");
  const [selectedPrompt, setSelectedPrompt] = useState("");
  // const [canCall, setCanCall] = useState(false);
  // const [isCoachFormExpanded, setIsCoachFormExpanded] = useState(false);
  const addPromptResponse = useAddPromptResponse();
  const [, copy] = useCopyToClipboard();
  const { toast } = useToast();

  const { firstName } = useGetName();
  const { data, isComplete, traceId } = useStreamText(userInput, selectedPrompt);

  const langfuseWeb = new LangfuseWeb({
    publicKey: process.env.REACT_APP_LANGFUSE_PUBLIC_KEY,
    baseUrl: "https://us.cloud.langfuse.com"
  });

  function handleCallCoach() {
    const cleanedAnswer = cleanUpPromptAnswer(promptAnswer, firstName);
    const input = "Feedback type that I seek:\n" + coach.name + "\n" + coach.description + "\n" + cleanedAnswer;
    setUserInput(input);
    setSelectedPrompt(coach.prompt_id);
  }

  const handleUserFeedback = async (value: number) => {
    try {
      await langfuseWeb.score({
        traceId,
        name: "user_feedback",
        value,
      });
      toast({
        title: "Feedback submitted",
        description: "Thank you for your feedback!",
      });
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast({
        title: "Error",
        description: "Failed to submit feedback. Please try again.",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    if (data || !isComplete) {
      setResponse(data);
    }
  }, [data, isComplete]);

  useEffect(() => {
    if (isComplete) {
      addPromptResponse.mutate({
        promptAnswerId: promptAnswer.id,
        newResponse: populatePromptResponse(activeKey, coach.name, data),
      });
      setUserInput("");
    }
  }, [isComplete]);

  const { mutate: updatePromptAnswer } = useEditPromptAnswerById();

  const updatePromptAnswerWithWaitingStatus = (promptAnswer: PromptAnswer) => {
    updatePromptAnswer({
      promptAnswerId: promptAnswer.id,
      updatedPromptAnswer: {
        ...promptAnswer,
        isWaitingHumanCoach: true,
      },
    });
  };

  return (
    <>
      <div className="shadow px-4 md:px-14 py-10 rounded-sm border max-w-[800px]">
        <h3 className="text-3xl font-semibold text-primary mb-6">{coach.name}</h3>
        <div className="md-container">
          {response ? (
            <>
              <Markdown>{response}</Markdown>
              {isComplete && (
                <p className="mt-16 text-xs italic">
                  By using the AI Coach, you acknowledge its role as a supportive guide, not a substitute for
                  professional advice, and accept responsibility for your actions and decisions. If you feel it made a
                  mistake, you can contact your human coach for further help.
                </p>
              )}
            </>
          ) : (
            <>
              <p>{coach.description}</p>
              <Button
                variant="primary"
                className="mt-8 min-w-36 py-2"
                isLoading={userInput.length > 0}
                loadingText="Asking AI Coach..."
                onClick={handleCallCoach}
              >
                Ask AI Coach
              </Button>
            </>
          )}
        </div>
      </div>
      {response && (
        <div className="mt-4">
          {traceId && (
            <>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <button className="mr-2 p-2 rounded-lg hover:bg-slate-100" onClick={() => handleUserFeedback(1)}>
                      <ThumbsUp className="text-slate-600" size={16} />
                    </button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Helpful</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <button className="mr-2 p-2 rounded-lg hover:bg-slate-100" onClick={() => handleUserFeedback(0)}>
                      <ThumbsDown className="text-slate-600" size={16} />
                    </button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Not Helpful</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </>
          )}
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <button
                  className="mr-2 p-2 rounded-lg hover:bg-slate-100"
                  onClick={() => {
                    copy(response);
                    toast({
                      title: "Feedback copied to clipboard",
                    });
                  }}
                >
                  <Copy className="text-slate-600" size={16} />
                </button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Copy</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <button
                  className="mr-2 p-2 rounded-lg hover:bg-slate-100"
                  onClick={() => {
                    setResponse("");
                    handleCallCoach();
                  }}
                >
                  <RefreshCw className="text-slate-600" size={16} />
                </button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Regenerate</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <AskHumanCoachForm
            promptAnswer={promptAnswer}
            updatePromptAnswerWithWaitingStatus={updatePromptAnswerWithWaitingStatus}
          />
        </div>
      )}
    </>
  );
}