import { useState } from "react";
import { useForm } from "react-hook-form";
import { Mail } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "@shadcn/popover";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@shadcn/tooltip";
import { Textarea } from "@shadcn/textarea";
import { Button } from "@shared/components/tremor/button";
import { useToast } from "@shadcn/use-toast";
import { useEmailHumanCoach } from "@/queries/promptAnswers";
import { PromptAnswer } from "@shared/types/prompt";

interface AskHumanCoachFormProps {
  promptAnswer: PromptAnswer;
  updatePromptAnswerWithWaitingStatus: (promptAnswer: PromptAnswer) => void;
}

export function AskHumanCoachForm({ promptAnswer, updatePromptAnswerWithWaitingStatus }: AskHumanCoachFormProps) {
  const [isCoachFormExpanded, setIsCoachFormExpanded] = useState(false);
  const { toast } = useToast();
  const { mutate: emailHumanCoach } = useEmailHumanCoach();
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmit = (formData: any) => {
    emailHumanCoach({ promptId: promptAnswer.id as string, question: formData["question-textarea"] });
    updatePromptAnswerWithWaitingStatus(promptAnswer);
    toast({
      title: "Question sent",
      description: "Your inquiry is now in the capable hands of a human coach.",
    });
    setIsCoachFormExpanded(false);
    reset();
  };

  return (
    <Popover open={isCoachFormExpanded} onOpenChange={setIsCoachFormExpanded}>
      <PopoverTrigger>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <button className="mr-2 p-2 rounded-lg hover:bg-slate-100">
                <Mail className="text-slate-600" size={16} />
              </button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Contact Human Coach for Further Help</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </PopoverTrigger>
      <PopoverContent className="md:min-w-[400px]">
        <>
          <h3 className="mt-2 text-2xl text-primary">Ask a Human Coach</h3>
          <p>Need further help from the AI Coach response? Ask a specific question</p>
        </>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Textarea className="my-4" {...register("question-textarea", { required: true })} />
          {errors["question-textarea"] && <p className="text-red-500">This field is required</p>}
          <Button type="submit" variant="primary" className="mr-2">
            Submit
          </Button>
          <Button variant="secondary" type="button" onClick={() => setIsCoachFormExpanded(false)}>
            Cancel
          </Button>
        </form>
      </PopoverContent>
    </Popover>
  );
}