import React, { ReactNode, Suspense, useEffect } from "react";
import { createBrowserRouter, Navigate, RouterProvider, useLocation } from "react-router-dom";
import MainContainer from "./layouts/MainContainer";
import AccountPage from "./pages/AccountPage";
import GoalView from "./pages/GoalPage/GoalView";
import Login from "./pages/LoginPage/Login";
import Logoff from "./pages/LoginPage/Logoff";
import NotFoundPage from "./pages/NotFoundPage";
import ProjectView from "./pages/ProjectPage/ProjectView";
import PublicProjectView from "./pages/TemplatePage/PublicProjectView";
import TemplatesLibraryView from "./pages/TemplatePage/TemplatesLibraryView";
// import { ThankYouPage } from "./pages/CheckoutPage/ThankYouPage";
import { useAuthContext } from "@/providers/AuthProvider";
import { usePostHog } from "posthog-js/react";
import LoadingIndicatorFullPage from "./components/loading-indicator/LoadingIndicatorFullPage";
import { Dashboard } from "./pages/Dashboard";
import { ErrorBoundary } from "./pages/ErrorBoundary";
import { Learn } from "./pages/Learn";
import { Northstar } from "./pages/Northstars";
import { CompletedPromptsPage } from "./pages/Prompts";
import { CreatePromptPage } from "./pages/Prompts/CreatePromptPage";
import { ExistingPromptPage } from "./pages/Prompts/ExistingPromptPage";
import { PromptLibrary } from "./pages/Prompts/PromptLibrary";
import { useLogUserActivity } from "@/queries/user";

interface ProtectedRouteProps {
  children: ReactNode;
}
const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { user, loading } = useAuthContext();
  const location = useLocation();
  const posthog = usePostHog();

  useEffect(() => {
    document.title = "Lifehack App";
  }, [location]);

  useEffect(() => {
    if (location && user && posthog) {
      console.log("tracking pv", user, location.pathname);
      posthog.capture("$pageview");
    }
  }, [location, user, posthog]);

  if (loading) {
    return null; // or a loading spinner
  }

  return user ? <>{children}</> : <Navigate to="/login" state={{ from: location.pathname }} />;
};

interface AdminRouteProps {
  children: ReactNode;
}

const AdminRoute = ({ children }: AdminRouteProps) => {
  const { user, isAdmin, loading } = useAuthContext();

  if (loading) {
    return null; // or a loading spinner
  }

  return user && isAdmin ? <>{children}</> : (<div>Error</div>);
};

const createProtectedRoute = (path: string, element: ReactNode) => ({
  path,
  element: (
    <ProtectedRoute>
      <RouteLogger />
      <MainContainer>{element}</MainContainer>
    </ProtectedRoute>
  ),
  errorElement: <ErrorBoundary />,
});

const createAdminRoute = (path: string, element: ReactNode) => ({
  path,
  element: (
    <AdminRoute>{element}</AdminRoute>
  ),
});

const RouteLogger = () => {
  const { loading } = useAuthContext();
  const location = useLocation();
  const { mutate: logUserActivity } = useLogUserActivity();
  const lastLoggedPath = React.useRef<string | null>(null);

  useEffect(() => {
    if (!loading && location.pathname !== lastLoggedPath.current) {
      logUserActivity({ event_name: 'page_view', path: location.pathname });
      lastLoggedPath.current = location.pathname;
    }
  }, [location, loading, logUserActivity]);

  return null;
};

const AppRouting = () => {
  const { loading } = useAuthContext();

  if (loading) {
    return null;
  }

  const CheckoutPage = React.lazy(() => import('./pages/CheckoutPage').then(module => ({ default: module.CheckoutPage })));
  const Admin = React.lazy(() => import('./pages/Admin').then(module => ({ default: module.Admin })));
  const AccountCreation = React.lazy(() => import('./pages/PasswordSetup/AccountCreation').then(module => ({ default: module.AccountCreation })));
  const CheckoutReturnPage = React.lazy(() => import('./pages/CheckoutPage/CheckoutReturnPage').then(module => ({ default: module.CheckoutReturnPage })));
  const ForgotPassword = React.lazy(() => import('./pages/PasswordSetup/ForgotPassword').then(module => ({ default: module.ForgotPassword })));
  
  const router = createBrowserRouter([
    createProtectedRoute("/", <Dashboard />),
    createProtectedRoute("/goals/:goalId", <GoalView />),
    createProtectedRoute("/logoff", <Logoff />),
    createProtectedRoute("/projects/:projectID", <ProjectView />),
    createProtectedRoute("/library", <TemplatesLibraryView />),
    createProtectedRoute("/account", <AccountPage />),
    createProtectedRoute("/prompts", <CompletedPromptsPage />),
    createProtectedRoute("/prompts/new", <PromptLibrary />),
    createProtectedRoute("/prompts/new/:promptID", <CreatePromptPage />),
    createProtectedRoute("/prompts/:promptAnswerId", <ExistingPromptPage />),
    createProtectedRoute("/learn/*", <Learn />),
    createProtectedRoute("/northstars", <Northstar />),
    createAdminRoute("/backoffice", <Suspense fallback={<LoadingIndicatorFullPage />}><Admin /></Suspense>),
    { path: "/login", element: <Login /> },
    { path: "/welcome", element: <Suspense fallback={<LoadingIndicatorFullPage />}><AccountCreation /></Suspense> },
    { path: "/checkout", element: <Suspense fallback={<LoadingIndicatorFullPage />}><CheckoutPage plan="monthly" /></Suspense> },
    { path: "/checkout/monthly", element: <Suspense fallback={<LoadingIndicatorFullPage />}><CheckoutPage plan="monthly" /></Suspense> },
    { path: "/checkout/yearly", element: <Suspense fallback={<LoadingIndicatorFullPage />}><CheckoutPage plan="yearly" /></Suspense> },
    { path: "/checkout/return", element: <Suspense fallback={<LoadingIndicatorFullPage />}><CheckoutReturnPage /></Suspense> },
    { path: "/forgot-password", element: <Suspense fallback={<LoadingIndicatorFullPage />}><ForgotPassword /></Suspense> },
    // { path: "/checkout/test-thankyou", element: <ThankYouPage customerEmail="test@test.com" />},
    { path: "/share/:shareHash", element: <PublicProjectView /> },
    { path: "*", element: <NotFoundPage /> },
  ]);

  return <RouterProvider router={router} />;
};

export default AppRouting;