/**
 * v0 by Vercel.
 * @see https://v0.dev/t/BtoYRN0NTJv
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { Button } from "@shadcn/button";
import { LineChart } from "lucide-react";
import prompts from "../Prompts/prompts.json";
import { AnswerNewMode } from "../Prompts/AnswerNewMode";
import { useState } from "react";
import { motion } from 'framer-motion';

export default function IncompleteScreen() {
  const [showAnswerNewMode, setShowAnswerNewMode] = useState(false);
  const promptData = prompts["BR3q2sV0j5Nx6HiLcMfA"];

  const handleStartAssessment = () => {
    setShowAnswerNewMode(true);
  };

  return (
    <div className="flex flex-col gap-6 p-8 md:p-12 container max-w-[800px]">
      <div className="flex flex-col items-start gap-4">
        <LineChart className="h-12 w-12 text-gray-500 dark:text-gray-400" />
        <h3 className="text-2xl font-bold">Welcome to Lifehack!</h3>
        <p className="text-gray-500 dark:text-gray-400">
          Unlock your comprehensive report with valuable insights and personalized feedback from your coach by
          completing the assessment.
        </p>
        <p className="text-gray-500 dark:text-gray-400">
          We&apos;re excited to have you on board. To kickstart your journey with us, we invite you to complete your
          Lifehack 360 Assessment. This essential step will help us understand your goals and challenges better,
          allowing us to provide you with personalized insights and valuable feedback from your coach.
        </p>
        <h4 className="text-xl font-semibold">Why Complete the Assessment?</h4>
        <ul className="list-disc list-inside text-gray-500 dark:text-gray-400">
          <li>Personalized Insights: Receive a detailed report tailored to your unique needs.</li>
          <li>Expert Feedback: Get actionable advice from your dedicated coach.</li>
          <li>Goal Clarity: Gain a clearer understanding of your strengths and areas for improvement.</li>
        </ul>
        <h4 className="text-xl font-semibold">How to Get Started:</h4>
        <ol className="list-decimal list-inside text-gray-500 dark:text-gray-400">
          <li>Click the &quot;Start Your Assessment&quot; button below.</li>
          <li>Answer a series of reflective questions designed to uncover your goals and challenges.</li>
          <li>Submit your responses and wait for your personalized report.</li>
        </ol>
        <p className="text-gray-500 dark:text-gray-400">
          Your journey to transformation begins here. Take the first step now!
        </p>
      </div>
      {!showAnswerNewMode && <Button onClick={handleStartAssessment}>Start Your Assessment</Button> }
      {showAnswerNewMode && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <AnswerNewMode formInitialData={promptData} isCallFromDashboard={true} />
        </motion.div>
      )}
    </div>
  );
}
