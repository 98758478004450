import { useAuthContext } from "@/providers/AuthProvider";
import { useFetchUserById } from "@/queries/user";
import { classNames } from "@/web/lib/utils";
import { CommandMenu } from "@/web/pages/Commands/CommandMenu";
import { Menu, Transition } from "@headlessui/react";
import { Avatar, AvatarFallback } from "@shadcn/avatar";
import { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";

export const ProfileDropdown = () => {
  const { user: authUser, loading } = useAuthContext();
  const uid = authUser?.uid || "";
  const { data: user } = useFetchUserById(uid);
  const [openCommand, setOpenCommand] = useState(false);

  if(loading) return null;

  const getInitial = (name: string) => {
    if (!name) return null;

    const names = name.split(" ");
    let initials = "";
    if (names.length > 0) {
      initials += names[0][0];
    }
    if (names.length > 1) {
      initials += names[names.length - 1][0];
    }
    return initials.toUpperCase();
  };

  const userInitial = user && user.name ? getInitial(user.name) : "";

  return (
    <>
      <Menu as="div" className="relative ml-3">
        <div>
          <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
            <span className="absolute -inset-1.5" />
            <span className="sr-only">Open user menu</span>
            <Avatar className="w-8 h-8 rounded-full">
              {/* <AvatarImage src={lifehackLogoAvatar} alt="LH" /> */}
              <AvatarFallback className="bg-secondary">{userInitial}</AvatarFallback>
            </Avatar>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-60 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              <div className="block px-4 py-2 text-sm text-gray-700">
                <div className="text-gray-900 font-medium">{user?.name}</div>
                <div>{user?.email}</div>
              </div>
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <NavLink
                  to="/account"
                  className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700")}
                >
                  Account Settings
                </NavLink>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "cursor-pointer flex justify-between px-4 py-2 text-sm text-gray-700"
                  )}
                  onClick={() => setOpenCommand(true)}
                >
                  Command Menu
                  <span className="inline-flex items-center gap-x-1 text-sm text-gray-600 dark:text-gray-400">
                    <kbd className="inline-flex justify-center items-center py-1 px-1.5 bg-white border border-gray-200 font-mono text-sm text-gray-800 rounded-md dark:bg-slate-900 dark:border-gray-700 dark:text-gray-200">
                      <svg
                        className="flex-shrink-0 h-3 w-3"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M15 6v12a3 3 0 1 0 3-3H6a3 3 0 1 0 3 3V6a3 3 0 1 0-3 3h12a3 3 0 1 0-3-3"></path>
                      </svg>
                    </kbd>
                    <kbd className="inline-flex justify-center items-center py-1 px-1.5 bg-white border border-gray-200 font-mono text-xs text-gray-800 rounded-md dark:bg-slate-900 dark:border-gray-700 dark:text-gray-200">
                      k
                    </kbd>
                  </span>
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <NavLink
                  to="/logoff"
                  className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700")}
                >
                  Sign out
                </NavLink>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
      <CommandMenu open={openCommand} setOpen={setOpenCommand} />
    </>
  );
};
