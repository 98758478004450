import { AlertCircle } from "lucide-react";
import React from "react";
import { Offline } from "react-detect-offline";
import "react-resizable/css/styles.css";
import { Alert, AlertDescription, AlertTitle } from "@shadcn/alert";
import { Toaster } from "@shadcn/toaster";
import { Navbar } from "./Navbar";

type MainContainerProps = {
  children: React.ReactNode;
};

function OfflineAlertBox() {
  return (
    <Alert className="container mt-4 mb-4" variant="destructive">
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>You have no connection</AlertTitle>
      <AlertDescription>Your data may not be saved. Please check your connection.</AlertDescription>
    </Alert>
  );
}

const MainContainer: React.FC<MainContainerProps> = ({ children }) => {
  return (
    <>
      <Toaster />
      <div className="w-full">
        <Navbar />
        <div id="main" className="mx-auto px-2 sm:px-6 lg:px-8">
          <Offline>
            <OfflineAlertBox />
          </Offline>
          {children}
        </div>
      </div>
    </>
  );
};

export default MainContainer;
