/**
 * v0 by Vercel.
 * @see https://v0.dev/t/fzQG1adgoZw
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { PromptAnswer } from "@shared/types";
import { ReportSection } from "./ReportSection";
import "@/styles/md-container.css";
import { Link } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@shadcn/alert-dialog";
import { Button } from "@shadcn/button";
import { Card, CardContent, CardFooter, CardHeader } from "@shadcn/card";

interface DashboardReportProps {
  prompt: PromptAnswer;
}

export default function DashboardReport({ prompt }: DashboardReportProps) {
  const firstUpdatedAt = prompt.responses?.[0]?.updatedAt;

  return (
    <Card className="mt-14">
      <CardHeader>
        <h1 className="mt-10 text-3xl pb-2 font-bold tracking-tight text-primary dark:text-gray-100">
          Your Lifehack 360 Report
        </h1>
        {firstUpdatedAt && (
          <p className="text-lg">
            {new Date(firstUpdatedAt.toDate()).toLocaleString("en-US", { year: "numeric", month: "long", day: "numeric" })}
          </p>
        )}
        </CardHeader>
        <CardContent className="mt-8 space-y-6">
          {/* Filter responses to only include those with responseTypeId starting with 'dashboard' and sort them by responseTypeId */}
          {prompt.responses
            ?.filter((response) => response.responseTypeId.startsWith("dashboard"))
            .sort((a, b) => a.responseTypeId.localeCompare(b.responseTypeId))
            .map((response) => <ReportSection key={response.id} response={response} />)}
        </CardContent>
      
      <CardFooter>
      <p className="pt-8 text-sm text-slate-600">
        We recommend that you{" "}
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button variant="link" className="p-0 h-auto font-normal underline">
              complete the Lifehack 360 Assessment
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Start a New Assessment?</AlertDialogTitle>
              <AlertDialogDescription>
                Are you ready to start a new Lifehack 360 Assessment? Completing this assessment will generate a fresh
                report from your coach, replacing the current one.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction asChild>
                <Link to="/prompts/new/BR3q2sV0j5Nx6HiLcMfA">Start New Assessment</Link>
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>{" "}
        at least once every quarter. Regularly reassessing yourself allows you to track your progress, identify new
        areas for improvement, and adjust your goals accordingly. By committing to this quarterly routine, you ensure
        that your personal and professional development stays on track, enabling you to continuously refine your
        strategies and achieve your North Star goals more effectively.
      </p>
      </CardFooter>
    </Card>
  );
}