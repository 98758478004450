import logo from "@/images/lifehack-logo-navbar.png";
// import { useAuthContext } from "@/providers/AuthProvider";
import { classNames } from "@/web/lib/utils";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "@shadcn/button";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { LearnOverlay } from "../../components/LearnOverlay";
import UserFeedbackPopup from "../../components/UserFeedbackPopup"; // Import the UserFeedbackPopup component
import { ProfileDropdown } from "./ProfileDropdown";
import { useUpdateNavigationBar } from "./useUpdateNavigationBar";
import { Notification } from "./Notification";

export function Navbar() {
  const [showIframe, setShowIframe] = useState(false);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false); // State to control feedback popup visibility
  // const { isAdmin } = useAuthContext();

  const initialNavigation = [
    { name: "Dashboard", href: "/", current: false },
    { name: "Growth Prompts", href: "/prompts", current: false },
    { name: "Northstars", href: "/northstars", current: false },
    { name: "Learn", href: "https://members.lifehack.org/", current: false },
  ];

  const navigation = useUpdateNavigationBar(initialNavigation);

  // Prevent body scrolling when iframe is open
  useEffect(() => {
    const body = document.body;
    if (showIframe) {
      body.classList.add("overflow-hidden");
    } else {
      body.classList.remove("overflow-hidden");
    }

    // Cleanup function to ensure the class is removed when the component unmounts
    return () => {
      body.classList.remove("overflow-hidden");
    };
  }, [showIframe]);

  return (
    <Disclosure as="nav" className="bg-primary sticky top-0 z-10">
      {({ open }) => (
        <>
          <div className="mx-auto w-full px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="hidden sm:flex flex-shrink-0 items-center">
                  <NavLink to="/">
                    <img className="h-8 w-auto" src={logo} alt="Lifehack" />
                  </NavLink>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        className={classNames(
                          item.current
                            ? "bg-[#204B40] text-white"
                            : "text-gray-300 hover:bg-[#15322A] hover:text-white",
                          "rounded-md px-3 py-2 text-sm font-medium"
                        )}
                        to={item.onClick ? "#" : item.href}
                        onClick={(e) => {
                          if (item.onClick) {
                            e.preventDefault();
                            item.onClick();
                          }
                        }}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>{" "}
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <Button
                  className="bg-white text-slate-900 rounded-full hover:bg-slate-300 max-h-[32px] mr-2"
                  onClick={() => setShowFeedbackPopup(true)} // Show the feedback popup
                >
                  Feedback
                </Button>
                <Notification />
                <ProfileDropdown />
              </div>
            </div>
          </div>
          {showFeedbackPopup && (
            <UserFeedbackPopup onClose={() => setShowFeedbackPopup(false)} /> // Pass onClose prop to close the popup
          )}

          {/* Overlay and Iframe */}
          {showIframe && <LearnOverlay show={showIframe} onClose={() => setShowIframe(false)} />}

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.onClick ? "#" : item.href}
                  className={classNames(
                    item.current ? "bg-gray-900 text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                    if (item.onClick) {
                      e.preventDefault();
                      item.onClick();
                    }
                  }}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}