import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthContext } from "@/providers/AuthProvider";
import { Breadcrumbs } from "@components/Breadcrumbs";
import { Button } from "@shadcn/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@shadcn/dropdown-menu";
import { Skeleton } from "@shadcn/skeleton";
import { debounce } from "lodash";
import { CalendarPlus, Check, MoreHorizontal } from "lucide-react";
import { useParams } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import { v4 as uuid } from "uuid";
import { useFetchGoalByGoalId } from "../../../queries/goals";
import {
  useAddTagsToProjectMutation,
  useCompleteProjectMutation,
  useFetchProjectByProjectId,
  useUpdateProjectMutation,
} from "../../../queries/projects";
import useSettingsStore from "@/store/settings";
import { AddTimeBlockDialog } from "../../components/AddTimeBlockDialog";
import { Editor } from "../../components/lexical/Editor";
import TimeblockList from "./TimeblockList";
import ContentEditable from "react-contenteditable";
import { useDocumentTitle } from "usehooks-ts";

const ProjectView: React.FC = () => {
  const navigate = useNavigate();
  const { isAdmin, loading } = useAuthContext();

  const { activeProject, updateSettings } = useSettingsStore();
  const { projectID: projectIdParams } = useParams<{ projectID: string }>();
  const projectID = projectIdParams || activeProject;

  const { isPending, data } = useFetchProjectByProjectId(projectID);

  const updateProject = useUpdateProjectMutation();
  const completeProject = useCompleteProjectMutation();
  const addTagsToProject = useAddTagsToProjectMutation();

  const [projectName, setProjectName] = useState("");

  const goalId = data?.goalId;
  const { data: goal } = useFetchGoalByGoalId(goalId);

  useEffect(() => {
    if (data) {
      setProjectName(data.name);
      updateSettings({ activeProject: projectID });
    }
  }, [data, updateSettings, projectID]);

  useDocumentTitle(projectName + " - Lifehack App");

  // Debounce the update function
  const debouncedUpdateProject = useCallback(
    debounce((newName: string) => {
      console.log("debouncedUpdateProject", newName);
      updateProject.mutate({ id: projectID, name: newName });
    }, 500),
    []
  );

  const projectNameRef = useRef<HTMLDivElement | null>(null);
  const currentValueRef = useRef(projectName);

  if (loading) return null;
  if (!projectID) return <div>Error</div>;

  const handleProjectNameBlur = () => {
    if (projectNameRef.current) {
      const newName = projectNameRef.current.textContent || "";
      if (newName !== currentValueRef.current) {
        currentValueRef.current = newName;
        setProjectName(newName);
        debouncedUpdateProject(newName);
      }
    }
  };

  const handleProjectNameChange = (event: React.FormEvent<HTMLDivElement>) => {
    const newName = event.currentTarget.textContent || "";
    setProjectName(newName);
    debouncedUpdateProject(newName);
  };

  if (isPending)
    return (
      <div className="w-full h-full flex justify-center items-center p-6">
        <div>
          <Skeleton className="h-4 w-[400px] mb-3" />
          <Skeleton className="h-4 w-[240px] mb-3" />
          <Skeleton className="h-4 w-[500px] mb-2" />
          <Skeleton className="h-4 w-[520px] mb-2" />
          <Skeleton className="h-4 w-[550px] mb-2" />
          <Skeleton className="h-4 w-[510px] mb-2" />
          <Skeleton className="h-4 w-[290px]" />
        </div>
      </div>
    );
  if (!data) return <div>No project found.</div>;

  const handleCompleteProject = () => {
    completeProject.mutate(projectID);
    updateSettings({ activeProject: "" });
    navigate("/");
  };

  //TODO: Test share and unshare project
  const shareProject = () => {
    if (data?.id) {
      updateProject.mutate({ id: projectID, shareHash: uuid(), publicStatus: true });
    }
  };

  const unshareProject = () => {
    if (data?.id) {
      updateProject.mutate({ id: projectID, shareHash: "", publicStatus: false });
    }
  };

  const copyUrlToClipboard = async () => {
    if (data?.shareHash) {
      try {
        await navigator.clipboard.writeText(`${window.location.origin}/share/${data.shareHash}`);
        console.log("URL copied to clipboard");
      } catch (err) {
        console.error("Failed to copy URL: ", err);
      }
    }
  };

  const handleTagsChange = (tags: string[]) => {
    addTagsToProject.mutate({ projectId: projectID, tags: tags });
  };

  return (
    <>
      {goal && data && (
        <div className="mx-auto max-w-[800px]">
          <div id="project-nav">
            <nav className="w-full py-8 flex justify-between items-center">
              <div className="flex items-center">
                <Breadcrumbs
                  pages={[
                    { name: "Northstars", href: "/northstars", current: false },
                    { name: goal.name, href: `/goals/${goal.id}`, current: false },
                    { name: projectName, href: "#", current: true },
                  ]}
                />
              </div>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" className="px-3 py-2">
                    <MoreHorizontal size={16} />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56">
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      onClick={() => {
                        handleCompleteProject();
                      }}
                    >
                      <Check className="mr-1 h-4 w-4" />
                      <span>Complete</span>
                    </DropdownMenuItem>
                    {isAdmin && data?.publicStatus && (
                      <DropdownMenuItem onClick={unshareProject}>
                        <span>Unshare</span>
                      </DropdownMenuItem>
                    )}

                    {isAdmin && !data?.publicStatus && (
                      <DropdownMenuItem onClick={shareProject}>
                        <span>Share</span>
                      </DropdownMenuItem>
                    )}

                    {isAdmin && data?.publicStatus && (
                      <DropdownMenuItem onClick={copyUrlToClipboard}>
                        <span>Copy Share URL</span>
                      </DropdownMenuItem>
                    )}
                    {isAdmin && data?.publicStatus && (
                      <TagsInput
                        value={data.tags}
                        onChange={handleTagsChange}
                        name="project-tags"
                        placeHolder="Enter tags"
                      />
                    )}
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            </nav>
          </div>
          <div className="timeblock-container group">
            <div className="flex space-x-4 mb-4 justify-items-stretch">
              <TimeblockList projectId={projectID} />

              <AddTimeBlockDialog projectId={projectID}>
                <Button variant="outline" className="p-5">
                  <CalendarPlus size={20} />
                </Button>
              </AddTimeBlockDialog>
            </div>

            <div className="mt-6">
              <ContentEditable
                html={projectName}
                tagName="h1"
                className="w-full font-argent text-3xl md:text-4xl font-bold p-3 focus:outline-none border-0 focus-visible:ring-0 focus-visible:ring-offset-0"
                innerRef={projectNameRef}
                onChange={handleProjectNameChange}
                onBlur={handleProjectNameBlur}
              />
            </div>
          </div>
          <Editor key={projectID} projectId={projectID} />
        </div>
      )}
    </>
  );
};

export default ProjectView;
