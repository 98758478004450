import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { captureException } from "../utils/logging";
import { setupConfig } from "./apiUtils";

const url = process.env.REACT_APP_AI_API_URL;

export { useFetchAiGoalRecomendations, useFetchAiProjectRecomendations, useFetchAiSituationTranscript, useFetchAiActionRecomendations, useFetchAiPromptAdvice };

type DefaultResponse = {
  recommendations: [];
};

type TranscriptResponse = {
  comment: string;
  transcripts: string;
};

const useFetchAiGoalRecomendations = (userInput: string) => {
  return useQuery<DefaultResponse, Error>({
    queryKey: ["ai", "goalRecommendation", userInput],
    queryFn: async () => callAPI("/v1/recommendations/goals", userInput),
    enabled: !!userInput,
  });
};

const useFetchAiProjectRecomendations = (userInput: string) => {
  return useQuery<DefaultResponse, Error>({
    queryKey: ["ai", "projectRecommendation", userInput],
    queryFn: async () => callAPI("/v1/recommendations/projects", userInput),
    enabled: !!userInput,
  });
};

const useFetchAiActionRecomendations = (userInput: string) => {
  return useQuery<DefaultResponse, Error>({
    queryKey: ["ai", "actionRecommendation", userInput],
    queryFn: async () => callAPI("/v1/recommendations/actions", userInput),
    enabled: !!userInput,
    retryDelay: (attemptIndex) => Math.min(3000 * 2 ** attemptIndex, 30000),
  });
};

const useFetchAiSituationTranscript = (userInput: string) => {
  return useQuery<TranscriptResponse, Error>({
    queryKey: ["ai", "situationRecommendation", userInput],
    queryFn: async () => callTranscriptAPI("/v1/recommendations/situation_transcript", userInput),
    enabled: !!userInput,
  });
};

const useFetchAiPromptAdvice = (userInput: string, promptId: string, canCall: boolean) => {
  return useQuery<string, Error>({
    queryKey: ["ai", "promptAdvice", promptId, userInput],
    queryFn: async () => callAdviceAPI(`/v1/recommendations/prompts/${promptId}`, userInput),
    enabled: !!canCall,
  });
};

async function callAPI(apiPath: string, userInput: string): Promise<DefaultResponse> {
  try {
    const config = await setupConfig();
    const { data } = await axios.post(url + apiPath, { text: userInput }, config);
    return data as DefaultResponse;
  } catch (error) {
    captureException("error calling transcript ai api", error as Error);
    throw error as Error;
  }
}

async function callTranscriptAPI(apiPath: string, userInput: string): Promise<TranscriptResponse> {
  try {
    const config = await setupConfig();
    const { data } = await axios.post(url + apiPath, { text: userInput }, config);
    return data as TranscriptResponse;
  } catch (error) {
    captureException("error calling transcript ai api", error as Error);
    throw error as Error;
  }
}

async function callAdviceAPI(apiPath: string, userInput: string): Promise<string> {
  try {
    const config = await setupConfig();
    const { data } = await axios.post(url + apiPath, { text: userInput }, config);
    return data;
  } catch (error) {
    captureException("error calling transcript ai api", error as Error);
    throw error as Error;
  }
}