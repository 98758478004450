/**
 * v0 by Vercel.
 * @see https://v0.dev/t/S63uZW1Cbks
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import UserMessagePopup from "@components/UserMessagePopup"; // Import the popup component
import { Button } from "@shadcn/button";
import { ClipboardCheckIcon } from "lucide-react";
import { useState } from "react";

export default function ProcessingScreen() {
  const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility

  const handleOpenPopup = () => setShowPopup(true);
  const handleClosePopup = () => setShowPopup(false);

  return (
    <div className="flex h-full flex-col items-center justify-center gap-6 p-8 md:p-12">
      <div className="flex flex-col items-center gap-4 text-center">
        <ClipboardCheckIcon className="h-12 w-12 text-gray-500 dark:text-gray-400" />
        <h3 className="text-2xl font-bold">Your 360 Assessment is Processing</h3>
        <p className="max-w-md text-gray-500 dark:text-gray-400">
          Your 360 Assessment has been completed and to be reviewed by your coach. If you haven&#39;t received your report
          within 24 hours, please contact us.
        </p>
      </div>
      <Button variant="outline" onClick={handleOpenPopup}>Message Your Coach</Button>
      {showPopup && <UserMessagePopup onClose={handleClosePopup} />}
    </div>
  );
}
