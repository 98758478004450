import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { collection, getDocs, orderBy, query, where, doc, updateDoc, addDoc, getDoc, limit } from "firebase/firestore";
import { initializeAuth, initializeFirestore } from "../utils/firebaseConfig";
import { Project } from "@shared/types";
import { captureException } from "../utils/logging";

export {
  useFetchActiveProjects,
  useFetchActiveProjectsByGoalId,
  useFetchCompletedProjectsByGoalId,
  useFetchProjectByProjectId,
  useCompleteProjectMutation,
  useUncompleteProjectMutation,
  useUpdateProjectMutation,
  useAddProjectMutation,
  usefetchAllPublicProjects,
  useFetchPublicProjectByShareHash,
  useAddTagsToProjectMutation,
};

const useFetchActiveProjects = () => {
  return useQuery<Project[], Error>({
    queryKey: ["projects", { completed: false }],
    queryFn: async () => fetchActiveProjects(),
  });
};

const useFetchActiveProjectsByGoalId = (goalId: string) => {
  return useQuery<Project[], Error>({
    queryKey: ["projects", { completed: false, goalId: goalId }],
    queryFn: async () => fetchActiveProjects(goalId),
    enabled: !!goalId,
  });
};

const useFetchCompletedProjectsByGoalId = (goalId: string) => {
  return useQuery<Project[], Error>({
    queryKey: ["projects", { completed: true, goalId: goalId }],
    queryFn: async () => fetchCompletedProjects(goalId),
    enabled: !!goalId,
  });
};

const useFetchProjectByProjectId = (projectId: string) => {
  return useQuery<Project, Error>({
    queryKey: ["projects", projectId],
    queryFn: async () => fetchProject(projectId),
  });
};

const useCompleteProjectMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (projectId: string) => {
      await updateProject({ id: projectId, completed: true });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects"] });
    },
  });
};

const useUncompleteProjectMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (projectId: string) => {
      await updateProject({ id: projectId, completed: false });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects"] });
    },
  });
};

const useUpdateProjectMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (project: Partial<Project>) => {
      await updateProject(project);
    },
    onSuccess: () => {
      //TODO: use setQueryData to update the project in the cache
      queryClient.invalidateQueries({ queryKey: ["projects"] });
    },
  });
};

const useAddProjectMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (project: Partial<Project>) => {
      return await addProject(project);
    },
    onSuccess: (data: Project) => {
      queryClient.setQueryData(
        ["projects", { completed: false, goalId: data.goalId }],
        (oldData: Project[] | undefined) => {
          return [...(oldData || []), data];
        }
      );
    },
  });
};

const useAddTagsToProjectMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ projectId, tags }: { projectId: string; tags: string[] }) => {
      return await addTagsToProject(projectId, tags);
    },
    onSuccess: (projectId: string) => {
      queryClient.invalidateQueries({ queryKey: ["projects", projectId] });
    },
  });
};

const addTagsToProject = async (projectId: string, tags: string[]) => {
  try {
    const db = initializeFirestore();
    const projectRef = doc(db, "projects", projectId);
    await updateDoc(projectRef, { tags });
    return projectId;
  } catch (error) {
    captureException("error adding tags to project", error as Error);
    throw error;
  }
};

const usefetchAllPublicProjects = () => {
  return useQuery<Project[], Error>({
    queryKey: ["projects", "public"],
    queryFn: fetchAllPublicProjects,
  });
};

const useFetchPublicProjectByShareHash = (shareHash: string) => {
  return useQuery<Project, Error>({
    queryKey: ["projects", "public", shareHash],
    queryFn: () => fetchPublicProjectByShareHash(shareHash),
  });
};

async function fetchPublicProjectByShareHash(shareHash: string): Promise<Project> {
  const db = initializeFirestore();

  const projectsCollectionRef = collection(db, "projects");
  const q = query(
    projectsCollectionRef,
    where("shareHash", "==", shareHash),
    where("publicStatus", "==", true),
    limit(1)
  );

  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    const doc = querySnapshot.docs[0];
    const project: Project = {
      id: doc.id,
      ...doc.data(),
    } as Project;

    return project;
  } else {
    console.log("No such document!");
    return {} as Project;
  }
}

async function fetchAllPublicProjects(): Promise<Project[]> {
  try {
    const db = initializeFirestore();
    const projectsCollectionRef = collection(db, "projects");
    const querySnapshot = await getDocs(
      query(projectsCollectionRef, where("publicStatus", "==", true))
    );
    const projects: Project[] = [];
    querySnapshot.forEach((doc) => {
      projects.push({
        id: doc.id,
        ...doc.data(),
      } as Project);
    });
    return projects;
  } catch (error) {
    captureException("error fetching public projects", error as Error);
    throw error;
  }
}

async function addProject(project: Partial<Project>) {
  try {
    if (!project.name) throw new Error("Project name is required");
    if (!project.goalId) throw new Error("Goal ID is required");

    const db = initializeFirestore();
    const auth = initializeAuth();
    if (!auth.currentUser) throw new Error("No user is currently signed in");
    const uid = auth.currentUser.uid;

    const finalProject = {
      ...project,
      name: project.name ?? "Untitled",
      goalId: project.goalId ?? "",
      userId: uid,
      details: project.details ?? "",
      completed: false,
      shareHash: "",
      publicStatus: false,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    const projectData = finalProject;

    console.log(projectData);

    const docRef = await addDoc(collection(db, "projects"), projectData);

    return { id: docRef.id, ...finalProject };
  } catch (error) {
    captureException("error adding project", error as Error);
    throw error;
  }
}

async function updateProject(project: Partial<Project>) {
  if (!project.id) throw new Error("Goal ID is required");

  try {
    const db = initializeFirestore();
    const projectRef = doc(db, "projects", project.id);
    const { id, ...fieldsToUpdate } = project;
    await updateDoc(projectRef, {
      ...fieldsToUpdate,
      updatedAt: new Date(),
    });
  } catch (error) {
    captureException("error updating project", error as Error);
    throw error;
  }
}

async function fetchProject(projectId: string): Promise<Project> {
  try {
    const db = initializeFirestore();
    const projectRef = doc(db, "projects", projectId);
    const projectSnapshot = await getDoc(projectRef);
    const project = projectSnapshot.data() as Project;
    return { ...project, id: projectId };
  } catch (error) {
    captureException("error fetching project", error as Error);
    throw error;
  }
}
async function fetchActiveProjects(goalId?: string): Promise<Project[]> {
  try {
    const db = initializeFirestore();
    const auth = initializeAuth();
    if (!auth.currentUser) throw new Error("No user is currently signed in");
    const uid = auth.currentUser.uid;

    const projectsCollectionRef = collection(db, "projects");
    let queryRef = query(
      projectsCollectionRef,
      where("completed", "==", false),
      where("userId", "==", uid),
      orderBy("name")
    );

    if (goalId) {
      queryRef = query(queryRef, where("goalId", "==", goalId));
    }

    const querySnapshot = await getDocs(queryRef);
    
    if (querySnapshot.empty) {
      return [];
    }

    const projects: Project[] = [];
    querySnapshot.forEach((doc) => {
      projects.push({
        id: doc.id,
        ...doc.data(),
        //FIXME: firestore will not return doc that has no updatedAt field. So if we sort it we want to it on runtime
        // For now we set it to the current date if not exists
        updatedAt: doc.data().updatedAt?.toDate() || new Date()
      } as Project);
    });
    return projects;
  } catch (error) {
    captureException("error fetching active projects", error as Error);
    throw error;
  }
}

async function fetchCompletedProjects(goalId: string): Promise<Project[]> {
  const db = initializeFirestore();
  try {
    const auth = initializeAuth();
    if (!auth.currentUser) throw new Error("No user is currently signed in");
    const uid = auth.currentUser.uid;

    const projectsCollectionRef = collection(db, "projects");
    const querySnapshot = await getDocs(
      query(
        projectsCollectionRef,
        where("completed", "==", true),
        where("userId", "==", uid),
        where("goalId", "==", goalId),
        orderBy("name")
      )
    );
    const projects: Project[] = [];
    querySnapshot.forEach((doc) => {
      projects.push({
        id: doc.id,
        ...doc.data(),
      } as Project);
    });
    return projects;
  } catch (error) {
    captureException("error fetching completed projects", error as Error);
    throw error;
  }
}